/**
 * Skrevet av Mikael
 */

 import React from 'react'

function Home() {
    
    return (
        <div>
            <h1>Home screen</h1>
        </div>
    )
}

export default Home