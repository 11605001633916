import React from 'react'

function BottomContainer(props) {
    return (
      <div className="bottomContainer box">
        
      </div>
    )
}

export default BottomContainer