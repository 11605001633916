/**
 * Skrevet av Mikael
 */

import React from "react";

function Footer() {
  return (
    <div>
      <footer>
        <p>dsfdsfdsfdsfdsfdsf</p>
        <p>
          sdfdsfdfdss
        </p>
      </footer>
    </div>
  );
}

export default Footer;
